/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * basic-sans:
 *   - http://typekit.com/eulas/00000000000000007735abd4
 *   - http://typekit.com/eulas/00000000000000007735abd7
 *   - http://typekit.com/eulas/00000000000000007735abe8
 *   - http://typekit.com/eulas/00000000000000007735abe6
 *   - http://typekit.com/eulas/00000000000000007735abed
 *   - http://typekit.com/eulas/00000000000000007735abf2
 *
 * © 2009-2025 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2025-03-19 09:43:28 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=frx2auv&ht=tk&f=35472.35473.35474.35476.35477.35478&a=193972351&app=typekit&e=css");

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/60b710/00000000000000007735abd4/30/l?subset_id=2&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/60b710/00000000000000007735abd4/30/d?subset_id=2&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/60b710/00000000000000007735abd4/30/a?subset_id=2&fvd=n9&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/4bfb19/00000000000000007735abd7/30/l?subset_id=2&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/4bfb19/00000000000000007735abd7/30/d?subset_id=2&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/4bfb19/00000000000000007735abd7/30/a?subset_id=2&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/49252d/00000000000000007735abed/30/l?subset_id=2&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/49252d/00000000000000007735abed/30/d?subset_id=2&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/49252d/00000000000000007735abed/30/a?subset_id=2&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"basic-sans";
src:url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

.tk-basic-sans { font-family: "basic-sans",sans-serif; }