@import url(_reset.scss);
@import url(_font.scss);

:root {
    --bodyPadding:64px;
    --navpadding:var(--bodyPadding);
    --navHeight:55px;
    --fontFamily: "basic-sans", sans-serif;
    --fontWeightReg:400;
    --fontWeightSemi:600;
    --fontWeightBold:700;
    --fontWeightMegaBold:900;

    --grad1:#7a36ff;
    --grad2:#66a6ff;

    --colTitle:#010101;
    --colSubTitle:#313031;
    --colBodyTxt:#666666;

    --colLI:#2365AF;
} 

$mq:760px;


.gradientcolorfortext {
    font-style: normal;
    background: linear-gradient(90deg, var(--grad1), var(--grad2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

body {
    font-family: var(--fontFamily);
}

*:focus-visible {
    outline: 2px solid #000;
    outline-offset: 2px;
}

.aui { 
    $self:&;
    background-color: #fff;

    &__nav {
        width:100%;
        height:var(--navHeight);
        padding:0px var(--navpadding); 
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        
        max-width: 1200px;
        margin: auto;
    }

    &__linked {
        width:20px;
        height:20px;
        border-radius: 20px;
        background-color: var(--colLI);
        display: block;
    }

    &__navtext {
        font-weight: var(--fontWeightMegaBold);
        font-size:0.75rem;
        text-transform: uppercase;
        letter-spacing: 0.20rem;
        color:#010101;
        position: relative;

        &:before {
            content:'';
            width:100%;
            height:2px;
            position: absolute;
            bottom:-1px;
            left:0px;
            opacity: 0;
            background-color: #010101;
            transition:all linear 0.08s;
        }


        &:hover {
            &:before {
                opacity:1;
                bottom:-4px;
                background-color: var(--grad1);
            }
        }

    }

    
    &__header {
        padding:120px 30px;
        //background-color: sandybrown;

        text-align: center;
        display: grid;
        gap:20px;
        align-content: center;
        border-bottom: 1px solid rgba(0 0 0 / 8%);
        border-top: 1px solid rgba(0 0 0 / 8%);
        background-color: #f6f6fc;

        @media (min-width: $mq) { 
            padding:180px 30px;
        }

    }

    &__herotxt {
        font-size:3rem;
        font-weight: var(--fontWeightMegaBold);
        line-height: 3.5rem;
        color:#010101;

    }

    &__herosubtxt {
        font-size:1.125rem;
        font-weight:  var(--fontWeightBold);        
        color:#333;
    }

    &__disclaimer {
        font-size:0.875rem;
        font-weight:  var(--fontWeightReg);        
        color:#A4B6CE;
    }

    @media (min-width: $mq) { 
        
        #{$self}__herotxt {
            font-size:4.625rem;
            line-height: 5rem;
            max-width: 800px;
            margin: auto;
        }

        &__herosubtxt {
            font-size:1.625rem;
        }
        
    }

    &__tags {
        margin:auto;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap:10px;
        max-width: 450px;

        #{$self}__tag {
            color:#222;
            border-radius: 30px;
            height:40px;
            padding:0px 16px;
            display: flex;
            align-items: center;
            font-weight: var(--fontWeightReg);
            border:1px solid rgba(0 0 0 / 12%);
            background-color: #fff;
            font-size: 0.875rem;


        }

    }

    &__main {
        padding:0px var(--bodyPadding);
        max-width: 1200px;
        margin: auto;
        display: grid;
        gap:120px;
        margin-top:100px;
        margin-bottom: 100px;
    }
 

    &__footer {

    }

    @media (min-width: $mq) { 
         
    }

}

.auiwork {
    $self:&;
    display: grid;
    grid-template-columns: 1fr;
    gap:50px;
    align-items: center;
    position:relative;

    @media (min-width: $mq) { 

        grid-template-columns: 1fr 1fr;

    }

    &__txt {

        display: grid;
        gap:12px;
        text-align: center;
        justify-items: center;

        &-title {
            font-weight: var(--fontWeightMegaBold);
            color:var(--colTitle);
            font-size:2.75rem ;
            position: relative;
            display: inline-block;
        }

        &-sub {
            font-weight: var(--fontWeightSemi);
            color:var(--colSubTitle);
            line-height: 1.9rem;
            font-size:1.125rem ;
        }

        &-body {
            font-weight: var(--fontWeightReg);
            color:var(--colBodyTxt);
            font-size:1rem ;
            line-height: 1.65rem;
        }

        @media (min-width: $mq) { 

            text-align: left;
            justify-items: baseline;

            #{$self}__txt-title {font-size:2.5rem ; }
            #{$self}__txt-sub {font-size:1.25rem ;}
            #{$self}__txt-body {font-size:1.125rem ;}
    
        }
    }

    &__herowrap {
        
        order:-1;

        #{$self}__hero {
            user-select: none;
            pointer-events: none;
            border-radius: 20px;
            box-shadow: 0px 0px 20px rgba(0 0 0 / 30%);
            max-width:100%;
        }

        @media (min-width: $mq) { 

            order:1;
    
        }

    }

    &__ctas {

        display: flex;
        gap:6px;
        margin-top:10px;
        justify-content: center;

        #{$self}__cta, #{$self}__ctagit {
            color:#fff;
            border-radius: 30px;
            height:44px;
            padding:0px 22px;
            display: flex;
            align-items: center; 
            font-weight: 400;     
            font-size:0.875rem;
            background-color: #7A36FF;

            &:hover {
                background-color: #5828b6;
            }

            &:active {
                transform: translateY(2px);
            }

        }

        #{$self}__ctagit {
            border:2px solid var(--colTitle);
            color:var(--colTitle);
            background-color: transparent;

            &:hover {
                background-color: rgba(0 0 0 / 5%);
            }



        }


        @media (min-width: $mq) { 

            justify-content: flex-start;
    
        }

    }

    &--beta {
        #{$self}__txt-title {
            &:after {
                content: "BETA";
                color: #fff;
                background-color: #f0a733;
                padding: 4px;
                font-size: .875rem;
                position: absolute;
                top: -18px;
                right: -31px;
                transform: rotate(6deg);

            }
        }
    }

    &--comingsoon {
        
        pointer-events: none;
        user-select: none;

        #{$self}__txt {
            opacity:0.125;
        }

        #{$self}__herowrap {
            opacity:0.125;
        }


        &:before {
            content:'Coming Soon, in progress';
            position: absolute;
            top:50%;
            right:50%;
            transform: translate(50%, -50%); 
            text-align: center;
            font-size: 1.25rem;
            font-weight: var(--fontWeightBold);


        }

    }

}