@import "https://p.typekit.net/p.css?s=1&k=frx2auv&ht=tk&f=35472.35473.35474.35476.35477.35478&a=193972351&app=typekit&e=css";
:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

figure {
  margin: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/60b710/00000000000000007735abd4/30/l?subset_id=2&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/60b710/00000000000000007735abd4/30/d?subset_id=2&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/60b710/00000000000000007735abd4/30/a?subset_id=2&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/4bfb19/00000000000000007735abd7/30/l?subset_id=2&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/4bfb19/00000000000000007735abd7/30/d?subset_id=2&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/4bfb19/00000000000000007735abd7/30/a?subset_id=2&fvd=i7&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/49252d/00000000000000007735abed/30/l?subset_id=2&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/49252d/00000000000000007735abed/30/d?subset_id=2&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/49252d/00000000000000007735abed/30/a?subset_id=2&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-basic-sans {
  font-family: basic-sans, sans-serif;
}

:root {
  --bodyPadding: 64px;
  --navpadding: var(--bodyPadding);
  --navHeight: 55px;
  --fontFamily: "basic-sans", sans-serif;
  --fontWeightReg: 400;
  --fontWeightSemi: 600;
  --fontWeightBold: 700;
  --fontWeightMegaBold: 900;
  --grad1: #7a36ff;
  --grad2: #66a6ff;
  --colTitle: #010101;
  --colSubTitle: #313031;
  --colBodyTxt: #666;
  --colLI: #2365af;
}

.gradientcolorfortext {
  background: linear-gradient(90deg, var(--grad1), var(--grad2));
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: normal;
}

body {
  font-family: var(--fontFamily);
}

:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #000;
}

.aui {
  background-color: #fff;
}

.aui__nav {
  height: var(--navHeight);
  padding: 0px var(--navpadding);
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
}

.aui__linked {
  background-color: var(--colLI);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
}

.aui__navtext {
  font-weight: var(--fontWeightMegaBold);
  text-transform: uppercase;
  letter-spacing: .2rem;
  color: #010101;
  font-size: .75rem;
  position: relative;
}

.aui__navtext:before {
  content: "";
  opacity: 0;
  background-color: #010101;
  width: 100%;
  height: 2px;
  transition: all 80ms linear;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.aui__navtext:hover:before {
  opacity: 1;
  background-color: var(--grad1);
  bottom: -4px;
}

.aui__header {
  text-align: center;
  background-color: #f6f6fc;
  border-top: 1px solid #00000014;
  border-bottom: 1px solid #00000014;
  align-content: center;
  gap: 20px;
  padding: 120px 30px;
  display: grid;
}

@media (width >= 760px) {
  .aui__header {
    padding: 180px 30px;
  }
}

.aui__herotxt {
  font-size: 3rem;
  font-weight: var(--fontWeightMegaBold);
  color: #010101;
  line-height: 3.5rem;
}

.aui__herosubtxt {
  font-size: 1.125rem;
  font-weight: var(--fontWeightBold);
  color: #333;
}

.aui__disclaimer {
  font-size: .875rem;
  font-weight: var(--fontWeightReg);
  color: #a4b6ce;
}

@media (width >= 760px) {
  .aui .aui__herotxt {
    max-width: 800px;
    margin: auto;
    font-size: 4.625rem;
    line-height: 5rem;
  }

  .aui__herosubtxt {
    font-size: 1.625rem;
  }
}

.aui__tags {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 450px;
  margin: auto;
  display: flex;
}

.aui__tags .aui__tag {
  color: #222;
  font-weight: var(--fontWeightReg);
  background-color: #fff;
  border: 1px solid #0000001f;
  border-radius: 30px;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-size: .875rem;
  display: flex;
}

.aui__main {
  padding: 0px var(--bodyPadding);
  gap: 120px;
  max-width: 1200px;
  margin: 100px auto;
  display: grid;
}

.auiwork {
  grid-template-columns: 1fr;
  align-items: center;
  gap: 50px;
  display: grid;
  position: relative;
}

@media (width >= 760px) {
  .auiwork {
    grid-template-columns: 1fr 1fr;
  }
}

.auiwork__txt {
  text-align: center;
  justify-items: center;
  gap: 12px;
  display: grid;
}

.auiwork__txt-title {
  font-weight: var(--fontWeightMegaBold);
  color: var(--colTitle);
  font-size: 2.75rem;
  display: inline-block;
  position: relative;
}

.auiwork__txt-sub {
  font-weight: var(--fontWeightSemi);
  color: var(--colSubTitle);
  font-size: 1.125rem;
  line-height: 1.9rem;
}

.auiwork__txt-body {
  font-weight: var(--fontWeightReg);
  color: var(--colBodyTxt);
  font-size: 1rem;
  line-height: 1.65rem;
}

@media (width >= 760px) {
  .auiwork__txt {
    text-align: left;
    justify-items: baseline;
  }

  .auiwork__txt .auiwork__txt-title {
    font-size: 2.5rem;
  }

  .auiwork__txt .auiwork__txt-sub {
    font-size: 1.25rem;
  }

  .auiwork__txt .auiwork__txt-body {
    font-size: 1.125rem;
  }
}

.auiwork__herowrap {
  order: -1;
}

.auiwork__herowrap .auiwork__hero {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  border-radius: 20px;
  max-width: 100%;
  box-shadow: 0 0 20px #0000004d;
}

@media (width >= 760px) {
  .auiwork__herowrap {
    order: 1;
  }
}

.auiwork__ctas {
  justify-content: center;
  gap: 6px;
  margin-top: 10px;
  display: flex;
}

.auiwork__ctas .auiwork__cta, .auiwork__ctas .auiwork__ctagit {
  color: #fff;
  background-color: #7a36ff;
  border-radius: 30px;
  align-items: center;
  height: 44px;
  padding: 0 22px;
  font-size: .875rem;
  font-weight: 400;
  display: flex;
}

.auiwork__ctas .auiwork__cta:hover, .auiwork__ctas .auiwork__ctagit:hover {
  background-color: #5828b6;
}

.auiwork__ctas .auiwork__cta:active, .auiwork__ctas .auiwork__ctagit:active {
  transform: translateY(2px);
}

.auiwork__ctas .auiwork__ctagit {
  border: 2px solid var(--colTitle);
  color: var(--colTitle);
  background-color: #0000;
}

.auiwork__ctas .auiwork__ctagit:hover {
  background-color: #0000000d;
}

@media (width >= 760px) {
  .auiwork__ctas {
    justify-content: flex-start;
  }
}

.auiwork--beta .auiwork__txt-title:after {
  content: "BETA";
  color: #fff;
  background-color: #f0a733;
  padding: 4px;
  font-size: .875rem;
  position: absolute;
  top: -18px;
  right: -31px;
  transform: rotate(6deg);
}

.auiwork--comingsoon {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.auiwork--comingsoon .auiwork__txt, .auiwork--comingsoon .auiwork__herowrap {
  opacity: .125;
}

.auiwork--comingsoon:before {
  content: "Coming Soon, in progress";
  text-align: center;
  font-size: 1.25rem;
  font-weight: var(--fontWeightBold);
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
/*# sourceMappingURL=index.c1676906.css.map */
